<template>
  <div class="flex_column">
    <dog-table
      ref="dogTable"
      id="e2acdb3b-f84d-4430-835d-29598072ed9d"
      :columns="tableColumns"
      :service="getList"
      :has-paging="false"
      column-type="selection"
      row-class-name="pointer"
      @selection-change="selectedData = $event"
      @row-click="rowClick"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-dictionary-dialog
      ref="addDictionaryDialog"
      @confirm="searchData"
    ></add-dictionary-dialog>
  </div>
</template>

<script>
  import dictionaryService from '@/api/service/dictionary';
  import addDictionaryDialog from './addDictionaryDialog.vue';

  export default {
    name: 'dictionaryManage__leftPart',
    components: { addDictionaryDialog },
    data() {
      return {
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 60,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <permission-element
                config={[
                  {
                    code: 'editDictionary',
                    callback: () =>
                      this.$refs.addDictionaryDialog.openFrame(row.id)
                  }
                ]}></permission-element>
            )
          },
          { prop: 'dictionaryName', label: '字典名', width: 100 },
          { prop: 'dictionaryValue', label: '字典值', width: 100 },
          {
            prop: 'dictionaryEnable_bn',
            label: '是否启用',
            width: 80,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.dictionaryEnable_bn}
                onInput={(val) => this.quickOperate(row.id, val)}></dog-switch>
            )
          }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addDictionary',
            callback: () => this.$refs.addDictionaryDialog.openFrame()
          },
          {
            buttonType: 'danger',
            code: 'deleteDictionary',
            callback: this.deleteDictionaries
          }
        ],
        selectedData: []
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.dogTable.search();
      },
      getList(params) {
        return dictionaryService.getDictionaryList(params).then((res) => {
          if (res.list[0]) {
            this.$refs.dogTable.$refs.baseTable.$refs.elTable.setCurrentRow(
              res.list[0]
            );
            this.$emit('select-dictionary', res.list[0]);
          }
          return res;
        });
      },
      quickOperate(id, val) {
        const transVal = val ? '1' : '0';
        this.$confirm(`是否确定${val ? '启用' : '禁用'}所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          dictionaryService
            .quickOperateDictionary({ id, dictionaryEnable: transVal })
            .then(this.searchData);
        });
      },
      deleteDictionaries() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          dictionaryService
            .deleteDictionaries({
              ids: this.selectedData.map((item) => item.id)
            })
            .then(this.searchData);
        });
      },
      rowClick(row) {
        this.$emit('select-dictionary', row);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
